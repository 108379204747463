.terms-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.terms-content {
    display: flex;
    flex-direction: column;
    padding: 2em;
    width: 80%;
    line-height: 1.5em;
}

.terms-content h3 {
    padding-top: 1em;
}

.prize-grid h4 {
    padding-top: 2em;
    padding-bottom: 1em;
}

.terms-content a {
    display: inline;
}

.terms-content ul {
    padding-left: 2em;
}

.terms-section {
    padding-top: 1em;
    padding-bottom: 1em;
}

.prize-grid {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
}

.prize-grid-number-column {
    width: 4em;
}

.terms-subject-line {
    font-weight: bold;
}