.order-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-content {
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.ticket-list {
    display: flex;
    flex-direction: column;
}

.order-customer-information {
    display: flex;
    flex-direction: column;
    width: 24em;
    height: 10em;
    padding: 2em
}

.order-billing-address {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    line-height: 1.5em;
}

.order-horizontal-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 64em;
}

.order-summary {
    display: flex;
    flex-direction: column;
    width: 24em;
    padding: 2em
}

.line-item-breakdown {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    width: 100%
}

.order-line-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1em;
}

.order-total-line-item-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    padding-bottom: 2em;
}

.order-total-line-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1em;
}

.order-line-item-text {
}

.order-line-item-value {
    font-weight: bold;
}

.order-total-text {
    /*font-size: 1.25em;*/
    font-weight: bold;
}

.order-total-value {
    font-size: 1.5em;
    font-weight: bold;
}

.order-ticket-numbers-header {
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.order-id {
    padding-top: 0.5em;
    font-size: 0.75em;
    color: #444444;
}

.ticket-email-message {
    padding-top: 0.75em;
    font-size: 0.75em;
    color: #444444;
}