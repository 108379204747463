.App {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-content {
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: white;
  /*height: 100%;*/
  max-width: 75%;
  margin-top:64px;
}

.App-link {
  color: #61dafb;
}

.app-drawer {
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.app-header-image {
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 48em;
}

.app-menu {
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  flex-direction: row;
  justify-content: space-between;
  max-width: 75%;
}

.app-menu a {
  padding: 0.1em;
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.content-container {
  display: flex;
  flex-direction: column;
  padding-top: 2em;
}
