.ticket-price-chart {
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 16em;
    height: 10em;
}

.ticket-price-title {
    padding-bottom: 1em;
 }

.ticket-price-item {
    font-size: 1.25em;
 }