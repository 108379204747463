.cart-summary-outer {
    display: flex;
    flex-direction: column;
    width: 24em;
    padding: 2em
}

.cart-summary {
    display: flex;
    flex-direction: column;
    width: 24em;
    max-height: 16em;
    flex-wrap: wrap;
    padding: 2em
}

.cart-total-line-item-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
}

.cart-total-line-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1em;
}

.cart-line-item-text {
}

.cart-line-item-value {
    font-weight: bold;
}

.cart-total-text {
    /*font-size: 1.25em;*/
    font-weight: bold;
}