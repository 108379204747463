.payment-form {
    display: flex;
    flex-direction: column;
    padding: 2em;
    max-width: 24em;
}

.payment-outer-content {
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.payment-horizontal-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 64em;
}

.payment-form-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.payment-form-field {
    display: flex;
    padding: 0.5em;
    width: 100%;
}

.payment-form-button {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding-top: 2em;
}

.payment-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.paysafe-js {
    height: 2em;
    margin-bottom: 1em;
    display: block;
    background-color: transparent;
    color: rgba(0, 0, 0, .87);
    border: 1px solid #E5E9EC;
    border-bottom: 1px solid rgba(0, 0, 0, .26);
    outline: 0;
    width: 100%;
    font-size: 16px;
    padding: 0.5em;
    box-shadow: none;
    border-radius: 0;
    position: relative;
}
