.ticket-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    height: 22em;
}

.ticket-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.ticket-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ticket-page-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 64em;
    padding: 2em;
}