.line-item-breakdown {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    width: 100%
}

.line-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0.5em;
}

.line-item-quantity-container {
    display: flex;
}

.line-item-price {
    display: flex;
}

.line-item-total-container-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    padding-bottom: 2em;
}

.line-item-total-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2em;
    font-weight: bold;
}

.line-item-total {
    display: flex;
    font-size: 1.5em;
}

.line-item-total-price {
    display: flex;
    align-items: end;
    font-size: 1.25em;
}