.address-form {
    display: flex;
    flex-direction: column;
    column-gap: 1em;
    padding: 2em;
}

.address-outer-content {
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.address-horizontal-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 64em;
}

.address-form-field {
    display: flex;
    padding: 0.5em;
    width: 100%;
}

.address-horizontal-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.address-continue-button {
    width: 16em;
}

.address-continue-button-container {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 2em;
}

.address-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}